<template>
  <div class="floor-layout tpl-12">
    <div v-for="(column, columnIndex) in data.columnList" :key="columnIndex" class="layout-main">
      <div class="layout-title" :style="colors(0).title">
        <template v-if="isEdit">
          <div class="edit-mask title" @click="handleEditTitle(columnIndex)">
            <el-button size="mini" type="primary" class="mask-btn-floor">
              <svg-icon icon-class="pen-leather"></svg-icon>
              编辑
            </el-button>
          </div>
          <div class="edit-mask tag" @click="handleEditTags(columnIndex)">
            <el-button size="mini" type="primary" class="mask-btn-floor">
              <svg-icon icon-class="pen-leather"></svg-icon>
              编辑
            </el-button>
          </div>
        </template>
        <h3 class="layout-item bz-tit">{{ column.title }}</h3>
        <div class="bz-tags">
          <a
            v-for="(tag, index) in column.tagList"
            :key="index"
            :href="blockHref(tag)"
          >
            <el-tag class="bz-tag-item">{{ tag.block_value }}</el-tag>
          </a>
        </div>
      </div>
      <div class="layout-body">
        <div class="bz-cover cover1" :class="{ 'last': index === 4, 'first': index === 0, 'first-line': index <= 4 && index >= 1 }"
             v-for="(item, index) in column.blockList">
          <layout-item :block="item" @handle-edit="handleEditBlock(columnIndex, index)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import mixin from '../mixin'
  import SvgIcon from "@/components/SvgIcon";

	export default {
		name: 'tpl-12',
    components: {SvgIcon},
    mixins: [mixin],
		title: '集市',
		dataTpl: {
			tpl_id: 12,
			tpl_type: 'ADV',
			columnList: [
				{
					title: '标题名称',
					titleColors: ['#333377', '#488bad'],
					tagList: mixin.methods.emptyBlock(0, 'TEXT'),
					blockList: mixin.methods.emptyBlock(9, 'IMAGE')
				}
			]
		}
	}
</script>

<style lang="scss" scoped>
.layout-title {
  .bz-tags {
    max-width: 970px
  }
}

.tpl-12 .layout-body {
  padding: 0;
  margin-top: 10px;
  background: none;
}

.tpl-12 .bz-cover {
  width: 18.5%;
  height: 300px;
  margin-right: 15px;

  &.first-line {
    margin-bottom: 15px;
  }
}

.tpl-12 .bz-cover:last-child {
  margin-right: 0;
}

.tpl-12 .bz-cover.last {
  margin-right: 0;
}

.tpl-12 .bz-cover.first {
  height: 615px;
}

/deep/ .cover1 {
  &.first {
    img {
      object-fit: cover;
    }
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    object-position: center;
  }
}
</style>
