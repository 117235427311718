<template>
  <div class="floor-layout tpl-8">
    <div v-for="(column, columnIndex) in data.columnList" :key="columnIndex" class="layout-main">
      <div class="layout-title" :style="colors(0).title">
        <template v-if="isEdit">
          <div class="edit-mask title" @click="handleEditTitle(columnIndex)">
            <el-button size="mini" type="primary" class="mask-btn-floor">
              <svg-icon icon-class="pen-leather"></svg-icon>
              编辑
            </el-button>
          </div>
          <div class="edit-mask tag" @click="handleEditTags(columnIndex)">
            <el-button size="mini" type="primary" class="mask-btn-floor">
              <svg-icon icon-class="pen-leather"></svg-icon>
              编辑
            </el-button>
          </div>
        </template>
        <h3 class="layout-item bz-tit">{{ column.title }}</h3>
        <div class="bz-tags">
          <a
            v-for="(tag, index) in column.tagList"
            :key="index"
            :href="blockHref(tag)"
          >
            <el-tag class="bz-tag-item">{{ tag.block_value }}</el-tag>
          </a>
        </div>
      </div>
      <div class="layout-body">
        <div class="bz-cover cover1" v-for="(item, index) in column.blockList">
          <layout-item :block="item" @handle-edit="handleEditBlock(columnIndex, index)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import mixin from '../mixin'
  import SvgIcon from "@/components/SvgIcon";

	export default {
		name: 'tpl-8',
    components: {SvgIcon},
    mixins: [mixin],
		title: '单列四图',
		dataTpl: {
			tpl_id: 8,
			tpl_type: 'ADV',
			columnList: [
				{
					title: '年货钜惠',
					titleColors: ['#333377', '#488bad'],
					tagList: mixin.methods.emptyBlock(0, 'TEXT'),
					blockList: mixin.methods.emptyBlock(4, 'IMAGE')
				}
			]
		}
	}
</script>

<style lang="scss" scoped>
.layout-title {
  .bz-tags {
    max-width: 970px
  }
}

.tpl-8 .layout-body {
  padding: 0;
  margin-top: 10px;
  background: none;
}

.tpl-8 .bz-cover {
  width: 24%;
  height: 295px;
}

.tpl-8 .bz-cover:last-child {
  margin-right: 0;
}

/deep/ .cover1 img {
  width: 100% !important;
  height: 100% !important;
}
</style>
