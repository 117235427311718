<template>
  <div class="floor-layout tpl-9">
    <div v-for="(column, columnIndex) in data.columnList" :key="columnIndex" class="layout-main">
      <div class="layout-title" :style="colors(columnIndex).title">
        <template v-if="isEdit">
          <div class="edit-mask title" @click="handleEditTitle(columnIndex)">
            <el-button size="mini" type="primary" class="mask-btn-floor">
              <svg-icon icon-class="pen-leather"></svg-icon>
              编辑
            </el-button>
          </div>
          <div class="edit-mask tag" @click="handleEditTags(columnIndex)">
            <el-button size="mini" type="primary" class="mask-btn-floor">
              <svg-icon icon-class="pen-leather"></svg-icon>
              编辑
            </el-button>
          </div>
        </template>
        <h3 class="layout-item bz-tit">{{ column.title }}</h3>
        <div class="bz-tags">
          <a
            v-for="(tag, index) in column.tagList"
            :key="index"
            :href="blockHref(tag)"
          >
            <el-tag class="bz-tag-item">{{ tag.block_value }}</el-tag>
          </a>
        </div>
      </div>
      <div class="layout-body">
        <div class="lo-bz lo-bz-1">
          <div class="bz-cover cover1">
            <layout-item :block="column.blockList[0]" @handle-edit="handleEditBlock(columnIndex, 0)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import mixin from '../mixin'
  import SvgIcon from "@/components/SvgIcon";

export default {
  name: 'tpl-9',
  mixins: [mixin],
  title: '四列四图',
  dataTpl: {
    tpl_id: 9,
    tpl_type: 'ADV',
    columnList: [
      {
        title: ' ',
        titleColors: ['#333377', '#488bad'],
        tagList: mixin.methods.emptyBlock(0, 'TEXT'),
        blockList: mixin.methods.emptyBlock(1, 'IMAGE')
      },
      {
        title: ' ',
        titleColors: ['#333377', '#488bad'],
        tagList: mixin.methods.emptyBlock(0, 'TEXT'),
        blockList: mixin.methods.emptyBlock(1, 'IMAGE')
      },
      {
        title: ' ',
        titleColors: ['#333377', '#488bad'],
        tagList: mixin.methods.emptyBlock(0, 'TEXT'),
        blockList: mixin.methods.emptyBlock(1, 'IMAGE')
      },
      {
        title: ' ',
        titleColors: ['#333377', '#488bad'],
        tagList: mixin.methods.emptyBlock(0, 'TEXT'),
        blockList: mixin.methods.emptyBlock(1, 'IMAGE')
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
.layout-main {
  float: left;
  width: 295px;
  overflow: hidden;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.tpl-9 .layout-body {
  padding: 0;
  margin-top: 10px;
}

.tpl-9 .layout-title {
  .edit-mask.tag {
    width: 220px
  }

  .bz-tags {
    max-width: 220px
  }
}

.tpl-9 .bz-cover {
  width: 100%;
  height: 295px;
}

/deep/ .cover1 img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  object-position: center;
}
</style>
