<template>
  <div class="container">
    <market-header/>

    <div class="banner">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(focus, index) in focusList" :key="index">
          <div class="focus-item">
            <img :src="focus.pic_url" class="focus-image"/>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </div>
    <div class="llk">
      <marketxs v-model="test"></marketxs>
    </div>
  </div>
</template>

<script>
import * as API_Floor from '@/api/floor';
import marketxs from './marketxs';
import SvgIcon from '@/components/SvgIcon';
import MarketHeader from '@/views/shopGoods/market-header';

export default {
  name: 'pcFloorManage',
  components: {
    MarketHeader,
    SvgIcon,
    marketxs
  },
  data() {
    return {
      test: '测试',
      child1: [],
      firstId: '',
      sencondId: '',
      ac: 1,
      // 楼层模板展开
      child: [],
      options: [{
        label: 'xxxx',
        value: 1,
        children: []
      }],
      value: '',
      focusList: [],
      floorOptions: {
        animation: 150,
        group: {
          name: 'tplGroup',
          put: true
        },
        sort: true,
        handle: '.handle-move'
      },
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          delay: 2000,
          // 当用户滑动图片后继续自动轮播
          disableOnInteraction: false
        },
        //开启循环模式
        loop: true
      },
      categoryListLevel1: [],
      floorList: [],
      keyWord: '',
      fdList: []
    };
  },
  mounted() {
    this.GET_FloorList();
  },
  computed: {
    // 楼层模板盒子样式
    tplBoxStyle() {
      const {sidebar} = this.$store.getters;
      let left = sidebar.opened ? 280 : 36;
      left = this.tplBoxShow ? left : left - 300;
      return {
        left: left + 'px'
      };
    }
  },
  methods: {
    /** 保存发布 */
    GET_FloorList() {
      API_Floor.getMarketFocus().then(res => {
        this.focusList = res;
      });
      API_Floor.getQFloor('PC', 'INDEX').then(response => {
        console.log(response, '楼层装修');
        this.floorList = JSON.parse(response.page_data || '[]');
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "./templates/floor-pc";

/deep/ .el-input-group--append .el-input__inner {
  border-top-left-radius: 0;
  padding-left: 28px;
}

/deep/ {
  .banner {
    height: 380px;
  }

  .banner .focus-item {
    height: 100%;
  }

  .banner img {
    display: block;
    width: 100%;
    height: 380px;
    object-fit: cover;
    object-position: center;
  }
}

/deep/ .el-input-group--append .el-input__inner {
  border-top-left-radius: 0;
}

/deep/ .el-input-group__append {
  color: white;
  background-color: rgba(26, 67, 169, 1);
}

p {
  margin: 0;
}

.mb {
  position: relative;
}

.cx {
  z-index: 111;
  position: absolute;
  top: 13px;
  left: 8px;
  color: gray;
}

.bm {
  width: 100%;
  height: 110px;
}

.pl {
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.zz {
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}

.mn {
  width: 100%;
  height: 230px;
}

.mn > div {
  width: 24.2%;
  height: 230px;
  display: inline-block;
  float: left;
  margin-right: 1%;
}

.mn > div:last-child {
  margin-right: 0;
}

.mn img {
  width: 100%;
  height: 100%;
}

.xs img {
  width: 100%;
  height: 100%;
}

.xs {
  width: 19.5%;
  display: inline-block;
  float: left;
  margin-right: 1%;
}

.xs > div {
  height: 500px;
  display: inline-block;
}

.xss img {
  width: 100%;
  height: 100%;
}

.xss {
  width: 79.5%;
  display: inline-block;
  float: left;
  height: 500px;
}

.xss > div > div {
  display: inline-block;
  height: 240px;
  width: 24.2%;
  margin-right: 1%;
  border: 1px solid rgba(128, 128, 128, 0.14);
}

.xss > div > div:last-child {
  margin-right: 0;
}

.ser {
  width: 400px;
  margin: auto;
}

.ser > div {
  text-align: left;
}

.ser .cser > div.active {
  background-color: rgba(26, 67, 169, 1);
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ser .cser > div {
  display: inline-block;
  height: 40px;
  width: 100px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #1a43a9;
  cursor: pointer;
}

.link {
  display: inline-block;
}

.link span {
  margin-left: 30px;
  cursor: pointer;
}

.searchBottom {
  text-align: left;
}

.container {
  width: 100%;
  background: #fff;
}

.flBox .menu {
  width: 200px;
  height: 48px;
  line-height: 48px;
  background: rgba(26, 67, 169, 1);
  text-align: center;
  color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.flBox ul {
  display: none;
  list-style: none;
  position: absolute;
  left: 0;
  width: 200px;
  top: 48px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  margin: 0;
  padding: 0;
  padding: 10px 0;
  height: 300px;
  overflow: auto;
}

.flBox ul li:hover {
  color: rgba(26, 67, 169, 1);
  cursor: pointer;
}

.flBox ul li {
  display: block;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
}

.flBox:hover ul {
  display: block;
}

.flBox ul:hover {
  display: block;
}

.flBox {
  position: relative;
  display: inline-block;
}

.search {
  padding-top: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.sInput {
  width: 400px;
  margin-bottom: 20px;
}

.floor-container {
  display: flex;
  justify-content: space-around;
  background-color: #e5e7ea;
  padding: 10px;
}
</style>
